<template>
  <HubModal
    id="dashboard-date-filter-modal"
    title="filters.dateFilter"
    icon="i-mdi-calendar-month-outline"
    confirm-text="filters.applyFilters"
    width="w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] lg:max-w-screen"
    @confirm="applyFilter"
    @cancel="cancelFilter"
  >
    <div class="grid grid-cols-2 gap-4 md:grid-cols-3">
      <URadio
        v-for="filterOption in filterOptions"
        :key="filterOption.value"
        v-bind="filterOption"
        color="trublue"
        :model-value="values.relativeDate"
        @update:model-value="applyDateFilter(undefined, undefined, $event)"
      />
    </div>

    <div class="my-4 flex items-center justify-center gap-4 text-xs text-grey-blue">
      <hr class="w-full">
      &nbsp;OR&nbsp;
      <hr class="w-full">
    </div>

    <HubCalendarInput
      id="dateRange"
      v-model="dateRange"
      class="w-full"
      selection-mode="range"
      select-other-months
      hide-on-range-selection
      date-format="dd M yy"
      :label="$t('filters.dateRange')"
      :placeholder="
        $t('reports.heatmap.timeFromTo', {
          minValue: now.subtract(7, 'd').format('DD MMM YYYY'),
          maxValue: now.format('DD MMM YYYY'),
        })
      "
      :max-date="new Date()"
      :manual-input="false"
      @update:model-value="dateRangeChanged"
    />
  </HubModal>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import type { HubFilter } from '~/types/app'

// imported refs
const now = dayjs()
const { t } = useI18n()

// props + emit
const props = withDefaults(
  defineProps<{
    dateTo?: string
    dateFrom?: string
    relativeDate?: string
    confirmFunction: (filter: HubFilter) => void
  }>(),
  {
    dateTo: undefined,
    dateFrom: undefined,
    relativeDate: props => (props.dateTo && props.dateFrom ? undefined! : 'previous|7|day'),
    confirmFunction: (_formFilter: HubFilter) => {}
  }
)

const emit = defineEmits<{
  (e: 'hideModal'): void
}>()

// local refs

const relativeDateOptions: Array<string> = [
  'previous|24|hour',
  'previous|7|day',
  'previous|1|month',
  'previous|3|month',
  'previous|6|month',
  'previous|1|year'
]
const values: Ref<HubFilter> = ref({
  dateFrom: props.dateFrom,
  dateTo: props.dateTo,
  relativeDate: props.relativeDate
})

const dateRange: Ref<Date[] | undefined> = ref()

// computed props
const filterOptions: ComputedRef<Array<{ label: string, value: string }>> = computed(() =>
  relativeDateOptions.map(x => ({
    value: x,
    label: getHumanRelativeDateTranslationString(x)
      // @ts-expect-error this works and is fine
      .map(y => (y.translationRequired ? t(y.string, y?.options) : y.string))
      .join(' ')
  }))
)

// methods
function applyFilter() {
  props.confirmFunction(values.value)
  emit('hideModal')
}

function cancelFilter() {
  // set formFilter back to default from props
  values.value = {
    dateFrom: props.dateFrom,
    dateTo: props.dateTo,
    relativeDate: props.relativeDate
  }
  emit('hideModal')
}

function dateRangeChanged(dateRange: Array<string> | undefined) {
  if (!dateRange || (dateRange && dateRange.filter(e => e).length < 2)) {
    applyDateFilter(undefined, undefined)
    return
  }

  const dateFrom = dayjs(dateRange[0]).format('YYYY-MM-DD')
  const dateTo = dayjs(dateRange[1]).format('YYYY-MM-DD')

  applyDateFilter(dateFrom, dateTo)
}

function applyDateFilter(
  dateFrom: string | undefined = undefined,
  dateTo: string | undefined = undefined,
  relativeDate: string | undefined = undefined
) {
  values.value = {
    dateFrom,
    dateTo,
    relativeDate
  }
}

watch(
  () => props,
  () => {
    if (props.dateFrom && props.dateTo) {
      dateRange.value = [new Date(props.dateFrom), new Date(props.dateTo)]
    }

    values.value = {
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      relativeDate: props.relativeDate
    }
  },
  {
    immediate: true,
    deep: true
  }
)
</script>
