<template>
  <UPopover
    v-model:open="showPopover"
    :close-delay="250"
    :popper="{ resize: true, scroll: false }"
    :ui="{
      width: 'w-60',
      base: 'h-auto text-sm text-clip text-wrap',
      popper: { strategy: 'fixed' },
    }"
  >
    <HubButton
      type="outlined"
      :label="$t('filters.outletTags.selector')"
      icon="i-mdi-plus"
      size="xs"
      btw-style="bg-white border-2 rounded-lg border-trublue text-trublue hover:bg-trublue hover:text-white rounded-lg pr-2 py-0.5"
    />
    <template #panel>
      <div class="px-3 pb-2 pt-4">
        <HubInput
          id="tag-search-panel"
          v-model="searchText"
          class="mb-4"
          :placeholder="$t('search')"
          trailing-icon="i-mdi-magnify"
        />

        <div
          v-if="filteredTags.length === 0 && searchText"
          class="text-center text-sm font-light text-grey-blue"
        >
          {{ $t('filters.outletTags.noSearchResults') }}
        </div>

        <div
          v-for="tag in filteredTags"
          :key="tag.name"
        >
          <div
            id="tag-type-title"
            class="text-xs font-medium uppercase text-dark-grey"
          >
            {{ tag.name }}
          </div>
          <div
            id="tag-type-values"
            class="my-2 ml-4 space-y-2"
          >
            <div
              v-for="value in tag.values"
              :key="value.value"
              class="text-sm text-grey-blue"
              :class="{
                'hidden': searchText && !value.value.toLowerCase().includes(searchText.toLowerCase()),
                'cursor-not-allowed text-mid-grey': selectedTags.includes(tag.name + '_' + value.value),
                'hover:text-primary cursor-pointer': !selectedTags.includes(tag.name + '_' + value.value),
              }"
              @click=" selectedTags.includes(tag.name + '_' + value.value)
                ? undefined
                : $emit('select', tag.name + '_' + value.value); showPopover = false"
            >
              {{ value.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
import type { OutletTag } from '~/types'

const props = defineProps<{
  tags: Array<OutletTag>
  selectedTags: Array<string>
}>()

defineEmits<{
  (e: 'select', value: string): void
}>()

const searchText: Ref<string> = ref('')
const showPopover: Ref<boolean> = ref(false)
const filteredTags = computed(() => {
  return props.tags.filter(tag =>
    tag.name.toLowerCase().includes(searchText.value.toLowerCase()) || tag.values.some(value => value.value.toLowerCase().includes(searchText.value.toLowerCase()))
  )
})
</script>
