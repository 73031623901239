<template>
  <HubModal
    id="dashboard-date-filter-modal"
    title="filters.outletTags.title"
    icon="i-mdi-tag"
    confirm-text="filters.applyFilters"
    width="w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] lg:max-w-screen"
    @confirm="confirmFunction({ orOutletTags, andOutletTags })"
    @cancel="setValuesFromParams"
  >
    <div id="or-outlet-tags">
      <h4 class="mb-4 text-xs font-semibold uppercase text-grey-blue">
        {{ $t('filters.outletTags.outletMatches') }}
      </h4>
      <div
        id="selected-or-outlet-tags"
        class="flex flex-wrap gap-4"
      >
        <div
          v-for="(tag, index) in orOutletTags"
          :key="tag"
          class="flex items-center"
        >
          <HubOutletTag
            :tag-type="tag.split('_')[0]"
            :tag-value="tag.split('_')[1]"
            @remove="orOutletTags.splice(index, 1)"
          />
          <div
            v-if="index < orOutletTags.length - 1"
            class="items-center pl-4 text-center text-sm uppercase text-grey-blue"
          >
            {{ $t('conjunctions.or') }}
          </div>
        </div>

        <HubOutletTagSelector
          :tags="relevantTags"
          :selected-tags="allSelectedTags"
          @select="$event => addTagToArray($event, 'or')"
        />
      </div>
    </div>
    <div
      v-if="orOutletTags.length"
      id="and-outlet-tags"
      class="mt-8"
    >
      <h4 class="mb-4 text-xs font-semibold uppercase text-grey-blue">
        {{ $t('filters.outletTags.andOutletMatches') }}
      </h4>
      <div
        id="selected-and-outlet-tags"
        class="flex flex-wrap gap-4"
      >
        <div
          v-for="(tag, index) in andOutletTags"
          :key="tag"
          class="flex items-center"
        >
          <HubOutletTag
            :tag-type="tag.split('_')[0]"
            :tag-value="tag.split('_')[1]"
            @remove="andOutletTags.splice(index, 1)"
          />
          <div
            v-if="index < andOutletTags.length - 1"
            class="items-center pl-4 text-center text-sm uppercase text-grey-blue"
          >
            {{ $t('conjunctions.and') }}
          </div>
        </div>

        <HubOutletTagSelector
          :tags="relevantTags"
          :selected-tags="allSelectedTags"
          @select="$event => addTagToArray($event, 'and')"
        />
      </div>
    </div>
  </HubModal>
</template>

<script setup lang="ts">
import type { HubFilter, OutletTag } from '~/types'

defineProps<{
  relevantTags: Array<OutletTag>
  confirmFunction: (filter: HubFilter) => void
}>()

const emit = defineEmits<{
  (e: 'update:relevant-tags', value: Array<OutletTag>): void
}>()

const filterStore = useFilterStore()
const organisationStore = useOrganisationStore()

const { currentOrganisationId } = storeToRefs(organisationStore)
const { params } = storeToRefs(filterStore)

const allTags: Ref<Array<OutletTag>> = ref([])
const orOutletTags: Ref<Array<string>> = ref([])
const andOutletTags: Ref<Array<string>> = ref([])

const allSelectedTags = computed(() => [...orOutletTags.value, ...andOutletTags.value])

async function getOutletTags() {
  if (!currentOrganisationId.value) return

  const queryParameters: { [key: string]: string | number | string[] | number[] | undefined } = {
    touchpointIds: params.value?.touchpointIds,
    organisationNodeIds: params.value?.organisationNodeIds
  }

  const hasQueryParameters = Object.values(queryParameters).some(value => value)
  allTags.value = await organisationStore.fetchOrganisationOutletTags(currentOrganisationId.value, hasQueryParameters ? queryParameters : undefined)

  // if we keep relevant tags as a v-model then we can update the parent component (and disable it when there are no tags)
  emit('update:relevant-tags', allTags.value.filter(tag => tag.type === 'custom'))
}

function setValuesFromParams() {
  orOutletTags.value = []
  andOutletTags.value = []

  const getAsArray = (value: string | Array<string>) => Array.isArray(value) ? value : [value]

  if (params.value.orOutletTags) {
    // @ts-expect-error will never be numbers, only strings
    orOutletTags.value = getAsArray(params.value.orOutletTags)
  }

  if (params.value.andOutletTags) {
    // @ts-expect-error will never be numbers, only strings
    andOutletTags.value = getAsArray(params.value.andOutletTags)
  }
}

function addTagToArray(tag: string, type: 'or' | 'and' = 'or') {
  switch (type) {
    case 'or':
      orOutletTags.value.push(tag)
      break
    case 'and':
      andOutletTags.value.push(tag)
      break
  }
}

watch(
  [() => params.value, () => currentOrganisationId.value],
  () => {
    getOutletTags()
    setValuesFromParams()
  },
  { deep: true, immediate: true }
)
watch(
  () => orOutletTags,
  () => {
    if (orOutletTags.value.length === 0) {
      // if there are no tags in the orOutletTags array, then we should clear the andOutletTags array because it is no longer relevant
      andOutletTags.value = []
    }
  }
)
</script>
